@charset "UTF-8";
// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

@import "layout";

// Import Font Awesome Icons Set
@import '@fortawesome/fontawesome-free/css/all.min.css';

// If you want to add custom CSS you can put it here.
@import "custom";
body {
  font-size: 0.875rem !important;
  color: #23282c;
	background-color: #e4e5e6;
  background-image: url("../assets/img/background.webp");
	background-repeat: space;
	background-position: center;
  background-size: auto;
  background-attachment: fixed;
}

.bg-approaching {
  background-color: #caf0f8;
	color: #23282c;
}
.bg-meets-low {
  background-color: #90e0ef;
	color: #23282c;
}
.bg-meets-mid {
  background-color: #00b4d8;
	color: #ffffff;
}
.bg-meets-high {
  background-color: #0077b6;
	color: #ffffff;
}
.bg-exceeds {
  background-color: #03045e;
	color: #ffffff;
}

.card-primary {
  color: #ffffff;
  background-color: #283891;
}

.card-secondary {
  color: #23282c;
  background-color: #c8ced3;
}

.card-info {
  color: #ffffff;
  background-color: #2982cc;
}

.card-contrast {
  color: #000000;
  background-color: #FFFFFF;
}

.danger-zone {
  border-radius: 5px;
  padding: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: #f86c6b;
  color: #f86c6b;
  text-align: center;
}

.bg-secondary {
  color: #23282c;
  background-color: #c8ced3;
}
.bg-light {
  background: transparent !important;
}
.cursor{
  cursor: pointer;
}

.text-positive {
  color: #4dbd74;
}
.text-negative {
  color: #f86c6b;
}

.score-background {
	position: relative;
	overflow: hidden;
}

.score-background::before {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	background-image: url("../assets/img/brand/brain.png");
	background-position: center;
	background-repeat: no-repeat;
	filter: opacity(0.20);
	transform: translate(-50%, -50%);
	z-index: 0;
}

.score-background > * {
	position: relative;
	z-index: 1;
}

.react-bootstrap-table {
  display: block;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}
.react-bootstrap-table table {
  table-layout: auto !important;
}
.table th,
.table td {
  white-space: wrap;
}

table.table-hover tbody tr:hover {
  background-color: #d9d9d9;
}

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
  color:#23282C;
}

.popover {
  max-width: 600px;
}

// classroom setup wizard classes
.signupNav {
  margin-bottom: 15px;
  text-align: center;
}

.iframe-container {
  overflow: hidden;
  margin: 15px auto;
  width: 100%;
  height: 250px;
  min-height: 260px;
  border-radius: 6px;
  padding: 4px;
  background-color: #f0f3f5;
  border: 1px solid #c8ced3;
}
.animated {
  animation-duration: 0.8192s;
  animation-fill-mode: backwards;
  transform-style: preserve-3d;
}

.main .container-fluid {
  padding: 0 5px;
}

.activeGroupTab {
  font-weight: bold;
}

.activeTab {
  font-weight: bold;
}

.bannerImage {
  max-width: 175px;
  min-width: 125px;
  width: 100%;
}
.rs_BannerImage {
  max-width: 130px;
  min-width: 80px;
  width: 100%;
}

// hiding results
.lockableContainerBlock {
  display: block;
}

.lockableContainerOverlayCurtain {
  position: absolute;
  top: 0;
  left: 0;
  display: inline;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.9;
  overflow: hidden;
}
.lockableContainerOverlayContent {
  position: absolute;
  padding-top: 5px;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 95%;
  margin: auto;
}
.lockableContainerOverlayContent .lock {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-height: 'fit-content';
}

.lockableContainerText {
  display: inline;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.lockableContainerText .lock {
  display: inline;
}
.lockableContainerText .lock span {
  display: inline;
  position: relative;
  color: #a1a2a3 !important;
}

.assessmentBanner {
  padding: 0px;
  background-color: #ebedef;
}
.assessmentBanner .logo {
  margin: auto;
}
.assessmentBanner .logo img {
  max-width: 200px;
}
.assessmentBanner .info {
  margin: auto;
}
.assessmentBanner .info div {
  padding: 10px;
  text-align: left;
}

.sessionInitiatedHeader {
  text-align: center;
}
.sessionInitiatedHeader img {
  max-width: 100px;
}
.sessionInitiatedHeader span {
  padding-left: 20px;
  font-size: 20px;
}

.orgGroupHeader {
  padding-bottom: 10px;
  font-size: 18px;
  color: #283891;
}

.orgGroupHeaderLogo {
  position: absolute;
  height: 75px;
  top: 0px;
  right: 0px;
}

.actionButton {
  background-position: left;
  background-position-x: 10px;
  background-repeat: no-repeat;
  background-size: auto 80% !important;
}
.actionButtonAssess {
  background-image: url("/assets/img/assess-button.png");
}

.actionButton span {
  margin-left: 30px;
  font-weight: bolder;
}

.actionButtonSmall {
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 90% !important;
	width:45px;
	height:30px;
	padding-top: 3px;
}
.actionButtonSmallAssess {
  background-image: url("/assets/img/assess-button.png");
}

.actionButtonSmall span {
  margin-left: 30px;
  font-weight: bolder;
  position: relative;
}

.programPeriodHeading {
  display: flex;
}
.programPeriodHeading .action {
  margin-left: 1px;
}

.programPeriodCOMPLETE {
  background-color: #c8ced3;
}
.programPeriodINPROGRESS {
  background-color: #c7eaf6;
}
.programPeriodSCHEDULED {
  background-color: #f0f3f5;
}

.pac-target-input {
  width: 100%;
  border: none;
}
.pac-target-input:focus {
  outline: none;
}
.pac-container{
  z-index: 9999!important;
}

@media print { 
  @page {
    margin-top: 10mm;
    margin-left: 10mm;
    margin-right: 10mm;
  }

  .card-group {
    display: flex;
    width: 100%;
    justify-content: center;
    align-self: center;
  }

  canvas {
    page-break-inside: avoid;
    height: 95% !important;
    width: 95% !important;
    justify-content: center;
  }
  .commonPageBreak {
    page-break-after: always !important;
    page-break-inside: avoid !important;
    page-break-before: avoid !important;
  }

  .printChart {
    height: 95% !important;
    width: 95% !important;
  }
  
  .pageBreakAfter {
    clear: both;
    page-break-after: always !important;
  }
  
  .pageBreakBefore {
    clear: both;
    page-break-before: always !important;
  }
  .pageBreakInside {
    page-break-inside: always !important;
  }
  .printContentCenter {
    justify-content: center !important;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-md-6 {
    width: 50%;
  }

  .printWidth50 {
    width: 50%;
  }

  .col-md-4 {
    width: 33.3333%;
  }

  .col-md-8 {
    width: 60%;
  }

  .printQRcode {
    width: 30% !important;
    height: 330px;
  }

  .printQRLogo {
    height: 35px;
  }

  .printLogo {
    height: 80px;
  }
  .printQRHeader {
    font-size: 10px;
    height: 20px;
  }

  .printQRImage {
    width: 71%;
  }

  .printQRFooter {
    font-size: 20px;
    size: 10;
  }

  .commonFontSizeForPrint {
    font-size: 8pt;
  }
  .commonFontSizePrint {
    font-size: 6pt;
  }

  .commonFontSizeHeaderForPrint {
    font-size: 12pt !important;
  }

  .commonFontSizeTableHeaderForPrint {
    font-size: 7pt !important;
  }

  .commonFontSizeTableItemForPrint {
    font-size: 6pt !important;
  }

  .table th {
    font-size: 7pt !important;
  }

  .table th,
  .table td {
  font-size: 6pt !important;
  }

  // #bootstrap-overrides {
  //   .pageBreakBefore {
  //     page-break-before: always !important;
  //   }
  // }
}

.logo-primary {
	display: inline-block;
	max-height: 90px;
	max-width: 250px;
}

.pac-container {
  z-index: 9999 !important;
}

/*
.header-fixed {
  display: block;
  .container-sm {
    margin: 0px;
    padding: 0px;
  }
  .animated {
    display: none;
  }
}*/

//Nav component css
.tab-content {
  margin-top: -1px;
  background: #fff;
  border: 1px solid #c8ced3;
}
.dropdown-item i {
  display: inline-block;
  width: 20px;
  margin-right: 10px;
  margin-left: -10px;
  text-align: center;
}
.dropdown-item {
  position: relative;
  padding: 10px 20px;
  border-bottom: 1px solid #c8ced3;
}
.card {
  margin-bottom: 1.5rem;
}
.tab-content .tab-pane {
  padding: 1rem;
}

//code overide
.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.dropdown-item {
  min-width: 180px;
}
.table-sm td,
.table-sm th {
  padding: 0.3rem;
}
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  transition: width 0.6s ease;
}
.float-right {
  float: right !important;
}
.text-value {
  font-size: 1.3125rem;
  font-weight: 600;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.form-group {
  margin-bottom: 1rem;
}

.nav-tabs .nav-link {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover {
  cursor: pointer;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 1rem;
  color: #23282c;
  background: #f0f3f5;
  border-top: 1px solid #c8ced3;
  position: fixed;
  bottom: 0px;
  width: 100%;
}
.table th {
  vertical-align: bottom;
}
.table td {
  vertical-align: middle;
}

.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.h5, h5 {
  font-size: 1.09375rem;
}
.app-header .dropdown-item,
.header .dropdown-item {
  min-width: 400px;
}
.btn {
  font-size: 0.875rem;
}
.dropdown-header {
  padding: 8px 20px;
  background-color: #e4e7ea;
  border-bottom: 1px solid #c8ced3;
}

//scroll UI changes
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  overflow-y: scroll;
  background: #eee;
  box-shadow: inset 0 0 4px #b2c7ea;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #b2c7ea;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #b2c7ea;
  border-radius: 10px;
}

.h4,h4 {
  font-size: 1.3125rem;
  width: 100%;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.nav-link:focus,
.nav-link:hover {
  text-decoration: none;
}

.table-striped {
  th {
    color: #222;
    font-weight: 700;
  }
}
.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-body {
  padding: 0.5rem 0.75rem;
  color: #23282c;
}
.programPeriodHeading .action {
  margin-left: 20px;
}
.programPeriodHeading {
  .header {
    border-bottom: none;
    padding: 0px;
  }
}
.h3,
h3 {
  font-size: 1.53125rem;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.table th {
  border-top: 1px solid #c8ced3;
  font-weight: 700;
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #f0f3f5;
  border-radius: 0.25rem;
}
.custom-switch {
  padding-left: 2.25rem;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5rem;
}
.custom-switch .custom-control-label:before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-control-label:before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s;
}
.custom-control-label:after,
.custom-control-label:before {
  position: absolute;
  top: 0.15625rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
}
.custom-control-label:before {
  pointer-events: none;
  background-color: #fff;
  border: 1px solid #8f9ba6;
}
.custom-switch .custom-control-label:after {
  top: calc(0.15625rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #8f9ba6;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.custom-control-label:after {
  background: no-repeat 50%/50% 50%;
}
*,
:after,
:before {
  box-sizing: border-box;
}
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-switch {
  padding-left: 2.25rem;
}
.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label:before {
  color: #fff;
  border-color: #20a8d8;
  background-color: #20a8d8;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label:after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.app-body {
  display: block;
}

.fab-help {
  position: fixed;
  bottom: 0px;
  right: 0px;
  font-size: 40px;
  margin: 20px;
  cursor: pointer;
}

.form-select {
  font-size: 14px;
}
.dropdown-item {
  position: relative;
  padding: 10px 20px;
  border-bottom: 1px solid #c8ced3;
  display: flex;
  // justify-content: space-between;
}
.dropdown-menu.show {
  font-size: 14px;
  padding: 0px;
}

.input-group > .input-group-append > .button, 
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.tab-content > .active {
  display: block;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #23282c;
  text-align: inherit;
  white-space: nowrap;
}
.dropdown-item .badge {
   margin-top: 2px;
}

.btn:hover {
  border: 1px solid transparent;
}

.report-button {
  border: 1px solid transparent;
  background-color: transparent;
}

.card-header > i {
  margin-right: 0.5rem;
  background: #f0f3f5;
  background: #f0f3f5 !important;
}

.btn-block {
  display: block;
  width: 100%;
}
.form-control:focus,
.form-select:focus,
.form-check-input:focus {
  color: #5c6873;
  outline: 0;
  border-color: $primary;
  box-shadow: 0 0 0 0.1rem #949cc8;
}
a {
  color: $primary;
  text-decoration: none;
  background-color: initial;
}
a:hover {
  color: #167495;
  text-decoration: underline;
}
.modal-header {
  background-color: #283891;
  color: #fff;
}
.btn-close {
  background-color: #283891;
  color: #fff !important;
}

.modal-footer {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #c8ced3;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  .btn-secondary {
    color: #23282c;
    background-color: #c8ced3;
    border-color: #c8ced3;
    // width: 50%;.btn:hove
  }

  .modal-footer > :not(:last-child) {
    margin-right: 0.25rem;
  }
}
.form-control:disabled {
  background-color: #e4e7ea;
  opacity: 1;
  border-color: transparent !important;
}
.form-control {
  font-size: 0.875rem;
  color: #5c6873 !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
	height: calc(1.5em + 0.75rem + 2px);
}
.table {
  color: #23282c !important;
}
.popover-body a {
  color: #20a8d8;
}
.popover-body a:hover {
  color: #167495;
}

.react-bootstrap-table .btn:focus {
  color: #23282c;
  background-color: #c8ced3;
  border-color: #c8ced3;
}
.form-check {
  margin-left: 1px;
}

.app-footer {
  -ms-flex: 0 0 50px;
  flex: 0 0 50px;
}

.input-group .input-group-text {
  height: 35px;
  border: 1px solid #ccc;
  background: #eee;
  border-radius: 4px 0px 0px 4px;
}

.modal-dialog {
  .nav-tabs .nav-item {
    margin-bottom: 0px;
  }
}
.search-container{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rstcustom__row{
  width: 100% !important;
}
.rstcustom__row{
  width: 98% !important;
  margin-left:1%;
}
.rstcustom__nodeContent{
width: 100%  !important;
}
.rstcustom__collapseButton, .rstcustom__expandButton {
  left: -44px !important;
  top: 35% !important;
}
.rstcustom__rowContents{
  height: 75% !important;
  border: 1px solid #ccc;
  box-shadow: none;
  background-color: #f0f3f5;
  margin-top: 2px;
}
.rstcustom__rowContents:hover{
  box-shadow:none;
  border: 1px solid #20a8d8;
}
.rstcustom__rowWrapper{
  padding: 0px !important;
}
.rstcustom__rowWrapper:hover{
  background: transparent !important;
  background: #fff;
  // padding: 2px 2px 2px 0px !important;
}
.rstcustom__nodeContent{
  background: #fff !important;
}

.sessionMain {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.sessionDialog {
  text-align: center;
  max-width: 350px;
}

.popupmodal-container{
  height: calc(100vh - 190px);
  overflow: auto;
  .rpv-core__inner-pages {
    overflow: unset;
  }
}

.report-session {
  margin: 0 10%;
  width: 90%;
}
.joyride-Container{
  h3{
    font-size: 38px;
    color:#283D8F;
  }
  p{
    font-size: 16px;
    line-height: 26px;
  }
  height: 100%;
}
.add-title{
  font-size: 26px;
  color: #283D8F;
  line-height: 31px;
  padding-left: 12px;
}
.react-joyride__tooltip{
  padding: 24px !important;
  button{
    padding: 6px 14px !important;
  }
  .font-weight-bold{
    font-weight: bold;
  }
}
.__floater__body button{
  right: 8px !important;
    top: 17px !important;
}
.rct-title{
  font-size: 0.875rem;
}

.group-container{
  border: 1px #e4e7ea solid;
  height: 250px;
  min-height: 200px;

}

@keyframes slideDown {
  0% {
    max-height: 0;
    opacity: 0;
  }
  100% {
    max-height: 200px;
    opacity: 1;
  }
}

.icon {
  font-size: 1.5rem;
  transition: transform 0.3s ease-in-out;
  display: inline-block;
  width: 1em;
  text-align: center;
}

.icon.open {
  transform: rotate(180deg);
}
.dropdown-container {
  max-height: 500px;
  overflow-y: auto;
}